.friendView {
    --friendView-background-color: white;

    --friendView-photo-size: 75%;
    --friendView-outline-width: 8%;
    --friendView-outline-margin: 2%;

    --friendView-outline-margin-size: calc(var(--friendView-photo-size) + 2 * var(--friendView-outline-margin));
    --friendView-outline-size: calc(var(--friendView-outline-margin-size) + 2 * (var(--friendView-outline-width)));

    background: var(--friendView-background-color);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: grid;
    grid-template-rows: auto 1fr;
}

.friendView_calendar {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.friendView_calendarBackground {
    position: absolute !important;
    margin: 0 !important;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0.75;
    transition: background-color 0.2s;
}

.friendView_friends {
    position: relative;
}

.friendView_friends-readOnly {
    pointer-events: none;
}

.friendView_friend {
    position: absolute;
    cursor: pointer;
}

.friendView_friend::before, .friendView_friend::after {
    content: "";
    display: block;
}

.friendView_friendFrame, .friendView_friend::before, .friendView_friend::after {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border-radius: 50%;
}

.friendView_friend::before {
    width: var(--friendView-outline-size);
    height: var(--friendView-outline-size);
    background-color: var(--friendView-background-color);
    transition: background-color 0.2s;
    z-index: 1;
}

.friendView_friend-status-1::before {
    background-color: #36AE7C;
}

.friendView_friend-status-2::before {
    background-color: #D9C345;
}

.friendView_friend-status-3::before {
    background-color: #EB5353;
}

.friendView_friend::after {
    width: var(--friendView-outline-margin-size);
    height: var(--friendView-outline-margin-size);
    background-color: var(--friendView-background-color);
    z-index: 2;
}

.friendView_friendFrame {
    width: var(--friendView-photo-size);
    height: var(--friendView-photo-size);
    background-size: cover;
    background-position: center center;
    z-index: 3;
}

.friendView_status {
    position: absolute;
    right: 4px;
    bottom: 4px;
    z-index: 4;
    opacity: 0.66;
}

.friendView_status-error {
    cursor: pointer;
}

.friendView_status .ui.message {
    padding: 6px 10px;
}
