.locationSelector {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
}

.locationSelector_auth {
    margin-top: 20px;
}
